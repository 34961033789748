<template>
  <div class="mycollection">
    <div class="mycollection_center">
      <!-- <header>
        <p>课程收藏</p>
        <el-button type="primary" @click="uploadresource" size="mini">
          <i class="btns_icon"></i>
          上传课程
        </el-button>
      </header>-->
      <nav>
        <!-- label-width="80px"  -->
        <el-form ref="resourceRef" :model="resourceForm" :inline="true">
          <el-form-item label="课程名称" prop="courseName" v-if="activeName=='first'">
            <el-input v-model="resourceForm.courseName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="系列课程名称" prop="seriseName" v-if="activeName=='second'">
            <el-input v-model="resourceForm.seriseName" @change="querylist" size="mini" clearable></el-input>
          </el-form-item>
          <el-form-item label="学科类型" prop="subjectTypeId" v-if="activeName=='first'">
            <el-select v-model="resourceForm.subjectTypeId" @change="changeshuject" size="mini" clearable>
              <el-option v-for="item in subjectypedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科" prop="subjectId" v-if="activeName=='first'">
            <el-select v-model="resourceForm.subjectId" size="mini" clearable>
              <el-option v-for="item in subjectdata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学段" prop="periodId" v-if="activeName=='first'">
            <el-select v-model="resourceForm.periodId" @change="changeperiod" size="mini" clearable>
              <el-option v-for="item in perioddata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年级" prop="gradeId" v-if="activeName=='first'">
            <el-select v-model="resourceForm.gradeId" size="mini" clearable>
              <el-option v-for="item in gradedata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课程标签" prop="labelId" v-if="activeName=='second'">
            <el-select v-model="resourceForm.labelId" size="mini" clearable>
              <el-option v-for="item in seriselabeldata" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="收藏时间">
            <el-form-item prop="startTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.startTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
            <el-form-item prop="endTime" style="width:150px">
              <el-date-picker
                type="date"
                placeholder="选择日期"
                v-model="resourceForm.endTime"
                style="width: 100%;"
                size="mini"
                clearable
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </el-form-item>
          </el-form-item>
        </el-form>
        <div class="nav_btns">
          <el-button type="primary" @click="querylist" size="mini">查询</el-button>
          <el-button @click="resetfrom('resourceRef')" size="mini">重置</el-button>
        </div>
      </nav>
      <main>
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="普通课程" name="first">
            <div v-if="emptyShow">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
              <div class="pcourse">
                <div v-for="item in listData" :key="item.id">
                  <card :carddata="item" :cardsite="0"></card>
                  <div class="card_footer">
                    <p class="card_one">收藏于：{{item.collcetTime}}</p>
                    <p class="card_two" @click="messageDeleteCollection(item.id,1)">
                      <i class="collcoticon"></i>
                      <span>取消收藏</span>
                    </p>
                  </div>
                </div>
                <div v-for="item in listnum" :key="item" style="width: 304px; height: 270px;"></div>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="系列课程" name="second">
            <div v-if="emptyShow">
              <el-empty description="暂无数据"></el-empty>
            </div>
            <div v-else>
              <div class="pcourse">
                <div v-for="item in listDatas" :key="item.id">
                  <cards :carddata="item"></cards>
                  <div class="card_footer">
                    <p class="card_one">收藏于：{{item.collectTime}}</p>
                    <p class="card_two" @click="messageDeleteCollection(item.id,2)">
                      <i class="collcoticon"></i>
                      <span>取消收藏</span>
                    </p>
                  </div>
                </div>
                <div v-for="item in listnums" :key="item" style="width: 304px; height: 270px;"></div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </main>
      <footer>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="curpage"
          :page-size="pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </footer>
    </div>
  </div>
</template>
<script>
import card from "../../../../components/card.vue";
import cards from "../../../../components/cards.vue";
export default {
  data() {
    return {
      // tabs切换
      activeName: "first",
      // 课程审核列表
      resourceForm: {
        courseName: null,
        seriseName: null,
        labelId: null, //系列课程标签
        subjectTypeId: null,
        subjectId: null,
        periodId: null,
        gradeId: null,
        startTime: null,
        endTime: null,
      },
      //学科类型
      subjectypedata: null,
      // 学科
      subjectdata: null,
      // 学段
      perioddata: null,
      // 年级
      gradedata: null,
      // 系列课程的标签
      seriselabeldata: null,
      //数据列表
      emptyShow: false,
      listData: null,
      listnum: null, //补齐普通课程
      listDatas: null,
      listnums: null, //补齐系列课程
      // 分页
      curpage: 1,
      pageSize: 10,
      total: null,
    };
  },
  created() {
    this.querySubjectType(); //获取课程类型
    this.querySubject(0); //获取学科信息
    this.queryPeriod(); //获取学段
    this.queryGrade(0); //获取年级信息
    this.querySeriseLable(); //获取系列课程的标签
    // 查询普通课程的收藏
    this.queryMyCollectCourse();
    //
    this.querySeriseCollectCourse();
  },
  methods: {
    // 查询普通课程的收藏
    async queryMyCollectCourse() {
      const { data, status } = await this.$Http.queryMyCollectCourse({
        // courseName: courseName, //课程名称
        // resourceType: resourceType, //课程类型
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //收藏开始时间
        // endTime:endTime,//收藏结束时间
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        console.log(data, status, "获取普通课程的收藏");
        if (data == null) {
          this.listData = data;
          this.emptyShow = true;
          this.total = 0;
        } else {
          let divisor = Math.trunc(data.total / this.pageSize);
          console.log(divisor, "除数");
          if (divisor < this.curpage) {
            let remainder = data.total % 5;
            console.log(remainder, "取余num");
            if (remainder !== 0) {
              this.listnum = 5 - remainder;
            }
          }

          this.listData = data.list;
          this.emptyShow = false;
          this.total = data.total;
        }
      }
    },
    // 获取系列课程的收藏
    async querySeriseCollectCourse() {
      const { data, status } = await this.$Http.querySeriseCollectCourse({
        // seriseName: this.resourceForm.seriseName, //课程名称
        // resourceType: resourceType, //课程类型
        // subjectTypeId: subjectTypeId, //课程类型ID
        // subjectId: subjectId, //学科ID
        // periodId: periodId, //学段ID
        // gradeId: gradeId, //年级ID
        // startTime: startTime, //收藏开始时间
        // endTime:endTime,//收藏结束时间
        ...this.resourceForm,
        pageNo: this.curpage, //页码
        pageSize: this.pageSize, //每页数量
      });
      if (status == 200) {
        console.log(data, status, "获取系列课程的收藏");
        if (data == null) {
          this.listDatas = data;
          this.emptyShow = true;
          this.total = 0;
        } else {
          let divisor = Math.trunc(data.total / this.pageSize);
          console.log(divisor, "除数");
          if (divisor < this.curpage) {
            let remainder = data.total % 5;
            console.log(remainder, "取余nums");
            if (remainder !== 0) {
              this.listnums = 5 - remainder;
            }
          }

          console.log(this.listnums, "listnumslistnums");
          this.listDatas = data.list;
          this.emptyShow = false;
          this.total = data.total;
        }
      }
    },
    //获取系列课程的标签
    async querySeriseLable() {
      await this.$Http.querySeriseLable().then((res) => {
        // console.log(res, "获取系列课程的标签");
        if (res.status == 200) {
          this.seriselabeldata = res.data;
        }
      });
    },
    // 获取课程类型
    async querySubjectType() {
      await this.$Http.querySubjectType().then((res) => {
        console.log(res, "获取课程类型");
        if (res.status == 200) {
          this.subjectypedata = res.data;
        }
      });
    },
    // 获取学科信息
    async querySubject(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.querySubject({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取学科");
        this.subjectdata = data;
      }
    },
    // 获取学段
    async queryPeriod() {
      await this.$Http.queryPeriod().then((res) => {
        console.log(res, "获取学段");
        if (res.status == 200) {
          this.perioddata = res.data;
        }
      });
    },
    // 获取年级信息
    async queryGrade(id) {
      console.log(id);
      let ids = [];
      if (id !== 0) {
        ids.push(id);
      }
      const { data, status } = await this.$Http.queryGrade({
        idList: ids,
      });
      if (status == 200) {
        console.log(data, status, "获取年级信息");
        this.gradedata = data;
      }
    },
    // 学科类型的值得变化
    changeshuject(val) {
      this.resourceForm.subjectId = null;
      if (!val) {
        this.querySubject(0);
      } else {
        this.querySubject(val);
      }
    },
    // 学段的值得变化
    changeperiod(val) {
      this.resourceForm.gradeId = null;
      if (!val) {
        this.queryGrade(0);
      } else {
        this.queryGrade(val);
      }
    },
    // 查询
    querylist() {
      if (this.activeName == "first") {
        this.queryMyCollectCourse();
      } else {
        this.querySeriseCollectCourse();
      }
    },
    // 重置
    resetfrom(formName) {
      console.log(this.$refs, "重置");
      this.$refs[formName].resetFields();
      this.querySubject(0); //获取学科信息
      this.queryGrade(0); //获取年级信息
      if (this.activeName == "first") {
        this.queryMyCollectCourse();
      } else {
        this.querySeriseCollectCourse();
      }
    },

    // 取消收藏
    async messageDeleteCollection(id, index) {
      const { data, status } = await this.$Http.messageDeleteCollection({
        serviceId: id,
        type: index,
      });
      if (status == 200) {
        // console.log(data, status, "取消收藏");
        this.$message.success("取消成功");
        this.queryMyCollectCourse();
        this.querySeriseCollectCourse();
      }
    },

    //tabs切换
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.index == 0) {
        this.curpage = 1;
        this.queryMyCollectCourse();
      } else {
        this.curpage = 1;
        this.querySeriseCollectCourse();
      }
    },
    // 分页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`, this.seriesId);
      this.curpage = val;
      if (this.activeName == "first") {
        this.queryMyCollectCourse();
      } else {
        this.querySeriseCollectCourse();
      }
    },
  },
  components: {
    card,
    cards,
  },
};
</script>
<style lang="scss" scoped>
.mycollection {
  .mycollection_center {
    // header{

    // }
    nav {
      background-color: #fff;
      padding: 15px;
      // .el-form-item{
      //   width: 230px;
      // }
      .el-input {
        width: 150px;
      }
      .el-select {
        width: 120px;
      }
      .nav_btns {
        display: flex;
        justify-content: flex-end;
      }
    }
    main {
      margin-top: 15px;
      padding: 10px 20px 0;
      background-color: #fff;
      .pcourse {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .card {
          margin-left: 12px;
          margin-right: 12px;
        }
        .cards {
          margin-left: 12px;
          margin-right: 12px;
        }
        .card_footer {
          display: flex;
          justify-content: space-between;
          margin: 0px 12px 10px;
          .card_one {
            display: inline-block;
            // width: 134px;
            // height: 18px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #5d6066;
            line-height: 30px;
            // vertical-align: middle;
          }
          .card_two {
            cursor: pointer;
            span {
              // width: 56px;
              // height: 18px;
              font-size: 14px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #1977f9;
              // line-height: 16px;
              vertical-align: middle;
            }
            .collcoticon {
              display: inline-block;
              width: 30px;
              height: 30px;
              vertical-align: middle;
              background: url("../../../../assets/icon/solidestar.png")
                no-repeat;
            }
          }
        }
      }
      ::v-deep .el-tabs__item {
        font-weight: 600;
      }
    }
    footer {
      background-color: #fff;
      display: flex;
      justify-content: center;
      padding: 50px 0;
    }
  }
}
</style>